import { APP_BASE_URL, shopConfig } from "./settings";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { createApp } from "@shopify/app-bridge";
import { redirectLogin } from "./auth";

const axiosInstance = axios.create({
  baseURL: `${APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const app = createApp({ ...shopConfig, forceRedirect: true });

const errorHandler = (error: any) => {
  if (error.response && error.response.status === 401) {
    redirectLogin();
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    errorHandler(error);
    return Promise.reject(error);
  }
);

// intercept all requests on this axios instance
axiosInstance.interceptors.request.use(function (config: any) {
  return getSessionToken(app) // requires an App Bridge instance
    .then((token) => {
      config.baseURL = `${APP_BASE_URL}`;
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
});

export default axiosInstance;
