import { createContext, useEffect, useState } from "react";
import { AppProvider } from "@shopify/polaris";
import { BrowserRouter } from "react-router-dom";
import { shopConfig, theme } from "./config/settings";
import translations from "@shopify/polaris/locales/en.json";
import { Provider as AppBridgeProvider } from "@shopify/app-bridge-react";
import AppLayout from "./components/layout/AppLayout";
import { Provider } from "react-redux";
import store from "./redux/Store";
import "./App.scss";
import i18n from "i18next";
import { app } from "./config/axiosInstance";
import { getBlockchains, Blockchain } from "./apis/Api";

export type State = {
  availableBlockchains: Blockchain[] | null;
};

export const StateContext = createContext({
  state: {} as State,
  setState: (newState: State) => {
    !newState &&
      (() => {
        return;
      })();
  },
});

export const StateProvider = StateContext.Provider;
export const StateConsumer = StateContext.Consumer;

const App = () => {
  const [globalState, setGlobalState] = useState<State>({
    availableBlockchains: null,
  });

  const updater = (newState: State) => {
    setGlobalState({ ...globalState, ...newState });
  };
  const initialContext = { state: globalState, setState: updater };

  useEffect(() => {
    // componentDidMount
    const initialRender = async () => {
      try {
        getBlockchains().then(({ data }) =>
          updater({ availableBlockchains: data })
        );
        const {
          staffMember: { locale },
        } = await app.getState();
        await i18n.changeLanguage(locale);
      } catch (error) {
        console.error(error);
      }
    };

    initialRender();
  }, []);

  const { colorScheme } = theme;

  return (
    <div style={{ height: "500px" }}>
      <StateProvider value={initialContext}>
        <BrowserRouter>
          <AppProvider colorScheme={colorScheme} i18n={translations}>
            <Provider store={store}>
              <AppBridgeProvider config={shopConfig}>
                <AppLayout i18n={i18n} />
              </AppBridgeProvider>
            </Provider>
          </AppProvider>
        </BrowserRouter>
      </StateProvider>
    </div>
  );
};

export default App;
