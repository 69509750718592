import nftReducer from "../redux/nfts/NftReducer";
import tourReducer from "../redux/appTour/tourReducer";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import toastReducer from "./toast/toastReducer";
import storage from "redux-persist/lib/storage";

const presistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const RootReducer = combineReducers({
  nft: nftReducer,
  tour: tourReducer,
  toast: toastReducer,
});

export default persistReducer(presistConfig, RootReducer);
