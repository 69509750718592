import {
  LIST_NFT_REQUEST,
  LIST_NFT_SUCCESS,
  LIST_NFT_ERROR,
  UPDATE_DRAFT_NFT_REQUEST,
  UPDATE_DRAFT_NFT_SUCCESS,
  UPDATE_DRAFT_NFT_ERROR,
  PUBLISH_NFT_REQUEST,
  PUBLISH_NFT_SUCCESS,
  PUBLISH_NFT_ERROR,
  DELETE_NFT_REQUEST,
  DELETE_NFT_SUCCESS,
  DELETE_NFT_ERROR,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_ERROR,
  ADD_IMAGE_NFT_REQUEST,
  ADD_IMAGE_NFT_SUCCESS,
  ADD_IMAGE_NFT_ERROR,
  CREATE_NFT_REQUEST,
  CREATE_NFT_SUCCESS,
  CREATE_NFT_ERROR,
} from "./NftTypes";
import { NFT } from "../../apis/Api";

// List of Nft
export function listOfNftRequest(data: any) {
  return {
    type: LIST_NFT_REQUEST,
    payload: data,
  };
}

export function listOfNftSuccess(data: any) {
  return {
    type: LIST_NFT_SUCCESS,
    payload: data,
  };
}

export function listOfNftError(data: any) {
  return {
    type: LIST_NFT_ERROR,
    payload: data,
  };
}

// Add image into nft
export function addImageInNftRequest(data: any) {
  return {
    type: ADD_IMAGE_NFT_REQUEST,
    payload: data,
  };
}

export function addImageInNftSuccess(data: any) {
  return {
    type: ADD_IMAGE_NFT_SUCCESS,
    payload: data,
  };
}

export function addImageInNftError(data: any) {
  return {
    type: ADD_IMAGE_NFT_ERROR,
    payload: data,
  };
}

// Edit NFt request
export function getImageRequest(data: any) {
  return {
    type: GET_IMAGE_REQUEST,
    payload: data,
  };
}

export function getImageSuccess(data: any) {
  return {
    type: GET_IMAGE_SUCCESS,
    payload: data,
  };
}

export function getImageError(data: any) {
  return {
    type: GET_IMAGE_ERROR,
    payload: data,
  };
}

// Update NFT
export function updateDraftNftRequest(data: any) {
  return {
    type: UPDATE_DRAFT_NFT_REQUEST,
    payload: data,
  };
}

export function updateDraftNftSuccess(data: any) {
  return {
    type: UPDATE_DRAFT_NFT_SUCCESS,
    payload: data,
  };
}

export function updateDraftNftError(data: any) {
  return {
    type: UPDATE_DRAFT_NFT_ERROR,
    payload: data,
  };
}

// Publish NFT
export function publishDraftNftRequest(data: any) {
  return {
    type: PUBLISH_NFT_REQUEST,
    payload: data,
  };
}

export function publishDraftNftSuccess(data: any) {
  return {
    type: PUBLISH_NFT_SUCCESS,
    payload: data,
  };
}

export function publishDraftNftError(data: any) {
  return {
    type: PUBLISH_NFT_ERROR,
    payload: data,
  };
}

// Delete NFT
export function deleteNftRequest(data: any) {
  return {
    type: DELETE_NFT_REQUEST,
    payload: data,
  };
}

export function deleteNftSuccess(data: any) {
  return {
    type: DELETE_NFT_SUCCESS,
    payload: data,
  };
}

export function deleteNftError(data: any) {
  return {
    type: DELETE_NFT_ERROR,
    payload: data,
  };
}

export function createDraftNftRequest(data: any) {
  return {
    type: CREATE_NFT_REQUEST,
    payload: data,
  };
}

export function createDraftNftSuccess(nft: NFT) {
  return {
    type: CREATE_NFT_SUCCESS,
    payload: nft,
  };
}

export function createDraftNftError(errorMessage: string) {
  return {
    type: CREATE_NFT_ERROR,
    error: errorMessage,
  };
}
