import { Component } from "react";
// import { RouteComponentProps } from "react-router";
// import { withRouter } from "react-router-dom";
import { Frame } from "@shopify/polaris";
import { i18n } from "i18next";
import Routes from "./Routes";
import TopBarMarkup from "./TopBarMarkup";
import ToastMarkup from "./ToastMarkup";
// import {
//   ClientRouter,
//   RoutePropagator,
//   Provider as AppBridgeProvider,
// } from '@shopify/app-bridge-react';

type Props = {
  i18n: i18n;
};
class AppLayout extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { i18n } = this.props;
    // const { history, location } = this.props;
    return (
      <Frame topBar={<TopBarMarkup i18n={i18n} />}>
        {/* <ClientRouter history={history} />
        <RoutePropagator location={location} /> */}
        <Routes />
        <ToastMarkup />
      </Frame>
    );
  }
}

//export default withRouter(AppLayout);
export default AppLayout;
