export const tourTypes = {
  // Loading State
  SET_LOADING: "SET_LOADING",

  // Sync Orders
  ONBOARDING_DETAILS_START: "ONBOARDING_DETAILS_START",
  ONBOARDING_DETAILS_END: "ONBOARDING_DETAILS_END",

  // Tour Complete By Id
  ONBOARDING_TOUR_COMPLETE_BY_ID_START: "ONBOARDING_TOUR_COMPLETE_BY_ID_START",
  ONBOARDING_TOUR_COMPLETE_BY_ID_END: "ONBOARDING_TOUR_COMPLETE_BY_ID_END",

  // Skip Tour
  ONBOARDING_SKIP_TOUR_START: "ONBOARDING_SKIP_TOUR_START",
  ONBOARDING_SKIP_TOUR_END: "ONBOARDING_SKIP_TOUR_END",

  // Restart Tour
  ONBOARDING_RESTART_TOUR_START: "ONBOARDING_RESTART_TOUR_START",
  ONBOARDING_RESTART_TOUR_END: "ONBOARDING_RESTART_TOUR_END",

  // Restart Tour
  ONBOARDING_COMPLETED_TOUR_START: "ONBOARDING_COMPLETED_TOUR_START",
  ONBOARDING_COMPLETED_TOUR_END: "ONBOARDING_COMPLETED_TOUR_END",
};
