import { SyntheticEvent } from "react";
import { _pagination } from "../config/settings";
import { debounce } from "lodash";

export const setPagination = function (
  page = _pagination.page,
  limit = _pagination.limit,
  after = null,
  totalPages = 0,
  total = 0
) {
  let pagination = _pagination;
  const from = page * limit - limit + 1;
  const to = totalPages === page ? total : limit * page;
  pagination = {
    ...pagination,
    hasNext: totalPages > page,
    hasPrevious: page > 1,
    page: page,
    limit: limit,
    after: after,
    showing: total > 0 ? `Showing ${from} to ${to} of ${total} entries` : null,
  };
  return pagination;
};

// https://lodash.com/docs/4.17.15#debounce
export function debounceEvent(...args: unknown[]) {
  // @ts-ignore not sure what this lodash does TODO: fix this as much
  const debouncedEvent = debounce(...args);
  return (e: SyntheticEvent) => {
    e.persist();
    return debouncedEvent(e);
  };
}

export function isEmpty(value: unknown) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}

export function convertToDateTimeString(timestamp: number) {
  const date = new Date(timestamp * 1000);
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "short",
    timeStyle: "medium",
  }).format(date);
}

export const toastObject = {
  message: "",
  isError: false,
  isActive: false,
};

export function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
