import axiosInstance from "../config/axiosInstance";

type Timestamp = {
  _nanoseconds: number;
  _seconds: number;
};

type NFT = {
  // Base NFT (status <= 4)
  nftId: string; // the NFT ID
  status: number; // the NFT status
  statusAt: string; // the string at which the status last changed
  statusHistory: {
    // the list of previous status and string
    status: number;
    statusAt: string;
  }[];
  blockchain: string; // the blockchain
  name: string; // the NFT name
  initialSupply: number; // the NFT initial supply
  imageFile?: string; // the image file name (e.g. xyz.jpg)
  animationFile?: string; // the animation file name (e.g. xyz.mp4)
  createdAt: string;
  externalLink?: string;
  description?: string;
  creatorMsg?: string;
  properties?: { [key: string]: string }; // a map of NFT properties

  // Published NFT (status >= 5)
  productId?: number;
  variantId?: number;
  sku?: string; // the shopify SKU
  ipfsLink?: string; // the NFT IPFS link
  currentSupply?: number; // the NFT current supply
  committedSupply?: number; // the committed number of sold to avoid oversold
};

enum ImportStatus {
  Queued = 0,
  Processing = 1,
  Failed = 2,
  Success = 3,
}
const importStatusKeys = Object.values(ImportStatus).filter(
  (value) => typeof value === "string"
);

type ImportNFT = {
  blockchain: string;
  contractAddr: string;
  tokenId: string;
  status: ImportStatus;
  statusAt: Timestamp;
  createdAt: Timestamp;
  nftImportId: string; //uuid;
  errorCode?: number /* NftImportErrorCode if status is FAILED
    0: PERMISSION_FAILED -> API call to MultiBaas to check which address is approved to transfer the token has failed
    1: NOT_APPROVED -> Token has not been approved to be transferred by the specified consignor address
    2: METADATA_FAILED -> API call to NFTPort (or other metadata service) to get token metadata has failed
    3: METADATA_INVALID -> Retrieved metadata is missing an NFT name or image url
    4: MEDIA_FAILED -> Failed to save the NFT media file to our apps storage bucket
    5: UNKNOWN_ERROR -> Unhandled error case
  */;
};

type NFTRequest = {
  name: string; // the NFT name
  blockchain: string; // the blockchain
  initialSupply: number; // the NFT initial supply
  externalLink?: string;
  description?: string;
  creatorMsg?: string;
  properties?: { [key: string]: string }; // a map of NFT properties
  imageUploadFile?: string; // the uploaded image filename (without the `/uploads/` prefix)
  animationUploadFile?: string; // the uploaded animation filename (without the `/uploads/` prefix)
};

type PostImageResponse = {
  bucket: string; // "mb-shopify-dev.appspot.com"
  contentDisposition: string; // "inline; filename*=utf-8''408d5335-6096-4a33-b92f-2c2d7ed053fb.png"
  contentEncoding: string; // "identity"
  contentType: string; // "image/png"
  crc32c: string; // "kjUmMA=="
  downloadTokens: string; // "5fb160cb-3908-4808-9332-4941603068c8"
  etag: string; // "COLA95bV1fgCEAE="
  generation: string; // "1656608931045474"
  md5Hash: string; // "e1dMBESY+KvbWqcQMcoswQ=="
  metageneration: string; // "1"
  name: string; // "uploads/408d5335-6096-4a33-b92f-2c2d7ed053fb.png"
  size: string; // "6427"
  storageClass: string; // "STANDARD"
  timeCreated: string; // "2022-06-30T17:08:51.082Z"
  updated: string; // "2022-06-30T17:08:51.082Z"
};

type Blockchain = {
  id: string;
  name: string;
  explorer_url: string; // e.g. https://mumbai.polygonscan.com
  base_fee: number; // e.g. 3.14 means 3.14 USD
  commission_rate: number; // e.g. 10 means 10% of the line item price
};

type Consignor = {
  blockchain: string; // the blockchain
  blockchainName: string;
  status: number; // the consignor status
  cost?: number; // the cost to create a consignor
  address?: string; // the address of the consignor (only for status = CONFIRMED)
};

type Order = {
  id: string;
  createdAt: string;
  sku: string;
  orderId: number;
  status: number; // the Order status
  statusAt: string; // the string at which the status last changed
  statusHistory: {
    // the list of previous status and string
    status: number;
    statusAt: string;
  }[];
  nftId: string;
  name: string; // name of the NFT
  blockchain: string;
  email: string;
  contractAddr?: string;
  tokenId?: string;
  walletAddr?: string;
  txHash?: string;
  fulfillmentId?: number;
};

async function fetchAsync<F extends (param: P) => Promise<R>, P, R>(
  func: F,
  param: P
) {
  const response = await func(param);

  return await response;
}

async function postAsync<F extends (data: D) => Promise<R>, D, R>(
  func: F,
  data: D
): Promise<R> {
  const response = await func(data);
  return response;
}

async function getListOfNft() {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/nfts`;

  return axiosInstance.request({
    method: "get",
    url: uri,
  });
}

async function getListOfImportNft({
  status,
  after,
}: {
  status?: Array<number | string>;
  after?: string;
} = {}): Promise<{ data: ImportNFT[] }> {
  const statusParam = status
    ? "&" + status.map((n) => `status=${n}`).join("&")
    : "";
  const afterParam = after ? `&after=${after}` : "";
  const param = [statusParam, afterParam].join("").replace(/^&/, "?");
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/nft-imports${param}`;

  return axiosInstance.request({
    method: "get",
    url: uri,
  });
}

async function createDraftNft(data: NFTRequest): Promise<NFT> {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/nfts`;
  const { data: createdNft } = await axiosInstance.request({
    method: "post",
    url: uri,
    data: data,
  });
  return createdNft;
}

async function createImportedNfts(data: {
  blockchain: Blockchain["id"]; // the blockchain
  contractAddr: string;
  tokenIds: string[];
}): Promise<void> {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/nft-imports`;

  return axiosInstance.request({
    method: "post",
    url: uri,
    data: data,
  });
}

async function publishNft(nftId: number, data: { status: 1 }) {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/nfts/${nftId}/status`;

  return axiosInstance.request({
    method: "post",
    url: uri,
    data: data.status,
  });
}

async function addImageInNft(data: any) {
  const uri = `https://firebasestorage.googleapis.com/v0/b/${data.bucketName}/o?name=uploads%2F${data.fileName}`;

  return axiosInstance.request({
    method: "post",
    url: uri,
  });
}

async function getBlockchains(): Promise<{ data: Blockchain[] }> {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/blockchains`;

  return axiosInstance.request({
    method: "get",
    url: uri,
  });
}

async function getConsignor(
  blockchainId: Blockchain["id"]
): Promise<Consignor> {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/consignors?blockchain=${blockchainId}`;
  const { data: consignors } = await axiosInstance.request({
    method: "get",
    url: uri,
  });
  if (consignors.length === 0) throw Error("No Consignors found");
  if (consignors.length > 1)
    throw Error(`Multiple Consignors found ${JSON.stringify(consignors)}`);

  return consignors[0];
}

async function getConsignors(): Promise<{ data: Consignor[] }> {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/consignors`;
  return axiosInstance.request({
    method: "get",
    url: uri,
  });
}

async function createConsignor(
  blockchainId: Blockchain["id"]
): Promise<{ data: Consignor[] }> {
  const uri = `${process.env.REACT_APP_BASE_URL}/api/v0/consignors`;
  return axiosInstance.request({
    method: "post",
    url: uri,
    data: {
      blockchain: blockchainId,
    },
  });
}

async function uploadToStorage(filename: string, file: File) {
  // TODO need to connect with Redux saga to reflect upload progressEvent
  const renamedFile = new File([file], filename, { type: file.type });
  const uri = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_BUCKET_NAME}/o?name=uploads%2F${filename}`;
  const response = await axiosInstance.postForm(uri, [renamedFile]);
  return response.data.name;
}

const validImageTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/svg+xml",
  "image/webp",
];

const validAnimationTypes = [
  "audio/wav", // wav
  "audio/mpeg", // mp3
  "audio/ogg", //ogg
  "video/webm", // webm
  "video/mp4", // mp4
  "video/m4v", // m4v
  "video/x-m4v", // m4v
  "video/ogg", //ogg, ogv
  "model/gltf+json", // gltf
  "model/gltf-binary", // glb
  "text/html", // .html,.htm
];

// Support specific 3d file extensions since the browser could not detect a content type.
const valid3dFileExtensions = ["glb", "gltf"];

export {
  NFT,
  ImportNFT,
  ImportStatus,
  NFTRequest,
  PostImageResponse,
  Blockchain,
  Consignor,
  Order,
  fetchAsync,
  postAsync,
  getListOfNft,
  getListOfImportNft,
  createDraftNft,
  createImportedNfts,
  publishNft,
  addImageInNft,
  getBlockchains,
  getConsignor,
  getConsignors,
  createConsignor,
  uploadToStorage,
  importStatusKeys,
  validImageTypes,
  validAnimationTypes,
  valid3dFileExtensions,
};
