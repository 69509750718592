import { PayloadAction } from "@reduxjs/toolkit";
import { NFT } from "../../apis/Api";
import {
  CREATE_NFT_SUCCESS,
  CREATE_NFT_REQUEST,
  CREATE_NFT_ERROR,
  type NftActionTypes,
} from "./NftTypes";

type State = {
  loading: boolean;
  data: NFT | null;
  error: boolean;
  errorMessage: string;
};

const initialState = {
  loading: true,
  data: null,
  error: false,
  errorMessage: "",
};

const reducer = (
  state: State = initialState,
  action: PayloadAction<NFT, NftActionTypes, any, any>
) => {
  switch (action.type) {
    case CREATE_NFT_REQUEST:
      return { ...state, data: action.payload, loading: true };

    case CREATE_NFT_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case CREATE_NFT_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
