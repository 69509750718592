import { createStore, applyMiddleware, compose } from "redux";
import rootSaga from "./RootSaga";
import RootReducer from "./RootReducer";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

type RootState = ReturnType<typeof RootReducer>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const sagaMiddleware = createSagaMiddleware();

const middleware = [];
middleware.push(sagaMiddleware);

let enhancer;
if (process.env.NODE_ENV === "development") {
  enhancer = composeWithDevTools(applyMiddleware(...middleware));
} else {
  enhancer = applyMiddleware(...middleware);
}

// @deprecated method TODO: fix this
const store = createStore(RootReducer, enhancer);
sagaMiddleware.run(rootSaga);

export default store;
export { RootState };
