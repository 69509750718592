import { call, put, takeEvery } from "redux-saga/effects";
import type { SagaIterator } from "redux-saga";
import { toastObject } from "../../helpers/appHelpers";
import { CREATE_NFT_REQUEST } from "./NftTypes";
import { createDraftNftSuccess, createDraftNftError } from "./NftActions";

import { createDraftNft, NFT, NFTRequest } from "../../apis/Api";
import { openToast } from "../toast/toastActions";
let toast = toastObject;

type ApiError = {
  response: {
    status?: number;
    data?: {
      message: string;
    };
  };
};

function isApiError(error: any): error is ApiError {
  return !!error?.response;
}

type SideEffectArgument = {
  type: string;
  payload: {
    request: NFTRequest;
    t: any; // TFunction<"translation", undefined>;
    callback: () => void;
  };
};
function* createNewNft(data: SideEffectArgument): SagaIterator {
  let message = "";
  let isError = false;

  try {
    isError = false;
    message = "New NFT Created successfully";
    // typescript/saga issue https://github.com/Microsoft/TypeScript/issues/2983#issuecomment-230404301
    // const response: ReturnType<typeof createDraftNft> = yield call(
    const response: NFT = yield call(createDraftNft, data.payload.request);
    yield put(createDraftNftSuccess(response));
  } catch (e) {
    console.error(e);
    isError = true;
    message = "Oppss...There is something wrong.";
    if (isApiError(e)) {
      message = e.response.data?.message ?? message;
      const { t } = data.payload;

      if (message === "'imageUploadFile' field is invalid") {
        message = t("common:nftImageRequired");
      }
      if (message === "'name' field is missing or invalid") {
        message = t("common:nftNameInvalid");
      }
      if (message === "'initialSupply' field is missing or invalid") {
        message = t("common:supplyInvalid");
      }
      if (message === "'externalLink' field is invalid") {
        message = t("common:externalLinkInvalid");
      }
      if (message === "'blockchain' field is missing or invalid") {
        message = t("common:blockchainInvalid");
      }

      yield put(createDraftNftError(message));
    }
  } finally {
    toast = { ...toast, message: message, isActive: true, isError: isError };
    yield put(openToast(toast));

    data.payload.callback();
  }
}

export default function* nftSaga() {
  yield takeEvery(CREATE_NFT_REQUEST, createNewNft);
}
