const base64 = require("base-64");
export const APP_DOMAIN_URL = "";
export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const queryParams = new URLSearchParams(window.location.search);

const host = queryParams.get("host");
const isUnavailable = (host: string | null): host is null => !host;

if (isUnavailable(host)) {
  window.location.href = "/access_denied";
  throw new Error("host unavailable");
}

export const shopConfig = {
  apiKey: process.env.REACT_APP_API_KEY!,
  host,
  shop: base64.decode(host).toString().split("/admin")[0],
};

export const toastObject = {
  message: "Opss..! something went wrong .",
  isError: false,
  isActive: false,
};

export const _pagination: {
  page: number;
  hasNext: boolean;
  hasPrevious?: boolean;
  limit: number;
  after: string | null;
  totalPages?: number;
  total?: number;
  showing?: string | null;
} = {
  page: 1,
  hasNext: false,
  hasPrevious: false,
  limit: 20,
  after: null,
};

export const theme = {
  colorScheme: "light" as const,
};

//Columns Key Global
export const COLUMNS_KEY = {
  NAME: "name",
  FIELDS: "field",
  SORTABLE: "sortable",
  TYPE: "type",
  LABEL: "label",
  PROGRESS: "progress",
  COLOR: "color",
  SORT: "sort",
};

export const themeData = [
  { label: "Test Shopify theme 1", value: 1 },
  { label: "Test Shopify theme 2", value: 2 },
  { label: "Lucent innovation - Nai-Jobs - 1.0", value: 3 },
];

//Date Display Format
export const DATE_DISPLAY_FORMAT = "DD MMM YYYY, hh:mm A";
export const DATE_FILTER_FORMAT = "YYYY-MM-DD";
export const DATE_FILTER_DISPLAY_FORMAT = "DD MMM YYYY";

// Tab data

export const settingValue = "{'completed':[1,2],'skipped':false}";
export const themeAccentColor = "#008060";
