export const LIST_NFT_REQUEST = "LIST_NFT_REQUEST";
export const LIST_NFT_SUCCESS = "LIST_NFT_SUCCESS";
export const LIST_NFT_ERROR = "LIST_NFT_ERROR";

// Create draft nft
export const CREATE_NFT_REQUEST = "CREATE_NFT_REQUEST";
export const CREATE_NFT_SUCCESS = "CREATE_NFT_SUCCESS";
export const CREATE_NFT_ERROR = "CREATE_NFT_ERROR";

// Add image into new nft
export const ADD_IMAGE_NFT_REQUEST = "ADD_IMAGE_NFT_REQUEST";
export const ADD_IMAGE_NFT_SUCCESS = "ADD_IMAGE_NFT_SUCCESS";
export const ADD_IMAGE_NFT_ERROR = "ADD_IMAGE_NFT_ERROR";

// Update Draft NFt
export const UPDATE_DRAFT_NFT_REQUEST = "UPDATE_DRAFT_NFT_REQUEST";
export const UPDATE_DRAFT_NFT_SUCCESS = "UPDATE_DRAFT_NFT_SUCCESS";
export const UPDATE_DRAFT_NFT_ERROR = "UPDATE_DRAFT_NFT_ERROR";

// Publish NFT
export const PUBLISH_NFT_REQUEST = "PUBLISH_NFT_REQUEST";
export const PUBLISH_NFT_SUCCESS = "PUBLISH_NFT_SUCCESS";
export const PUBLISH_NFT_ERROR = "PUBLISH_NFT_ERROR";

// Delete An NFT
export const DELETE_NFT_REQUEST = "DELETE_NFT_REQUEST";
export const DELETE_NFT_SUCCESS = "DELETE_NFT_SUCCESS";
export const DELETE_NFT_ERROR = "DELETE_NFT_ERROR";

// Image
export const GET_IMAGE_REQUEST = "GET_IMAGE_REQUEST";
export const GET_IMAGE_SUCCESS = "GET_IMAGE_SUCCESS";
export const GET_IMAGE_ERROR = "GET_IMAGE_ERROR";

const actionsTypes = [
  LIST_NFT_REQUEST,
  LIST_NFT_SUCCESS,
  LIST_NFT_ERROR,
  UPDATE_DRAFT_NFT_REQUEST,
  UPDATE_DRAFT_NFT_SUCCESS,
  UPDATE_DRAFT_NFT_ERROR,
  PUBLISH_NFT_REQUEST,
  PUBLISH_NFT_SUCCESS,
  PUBLISH_NFT_ERROR,
  DELETE_NFT_REQUEST,
  DELETE_NFT_SUCCESS,
  DELETE_NFT_ERROR,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_ERROR,
  CREATE_NFT_SUCCESS,
  CREATE_NFT_REQUEST,
  CREATE_NFT_ERROR,
] as const;

export type NftActionTypes = (typeof actionsTypes)[number];
