import { call, put, takeLatest } from "redux-saga/effects";
import { onboardingEnd } from "./tourActions";
import { tourTypes } from "./tourTypes";

let arr: Array<number> = [];

// Get Onboarding Details
function* getOnBoardingDetails(data: any) {
  try {
    yield call(data.payload.callback);
  } catch (e) {
    console.warn("getOnBoardingDetails", e);
  }
}

// Get Onboarding Details
function* tourCompleteById(data: any) {
  try {
    const tourNumber = arr.find(
      (completedTour: number) => completedTour === data.payload.request.id
    );
    const completedCount = data.payload.request.completed.length;

    if (data.payload.request.completed) {
      if (tourNumber) {
        let arr2 = data.payload.request.completed;
        arr2.pop();
        arr = arr2;
      } else {
        arr = data.payload.request.completed;
      }
    }

    let structure = {
      completed: arr,
      tourCompleted: completedCount === 5 ? true : false,
      skip: false,
    };

    yield put(onboardingEnd(structure));
  } catch (e) {
    console.error(e);
  } finally {
    data.payload.callback();
  }
}

export default function* tourSaga() {
  yield takeLatest(tourTypes.ONBOARDING_DETAILS_START, getOnBoardingDetails);
  yield takeLatest(
    tourTypes.ONBOARDING_TOUR_COMPLETE_BY_ID_START,
    tourCompleteById
  );
}
