import { tourTypes } from "./tourTypes";

type TourData = {
  completed: Array<number>;
  skip: boolean;
  tourCompleted: boolean;
};

type TourState = {
  data: TourData | null;
  loading: boolean;
  error: any;
};

// Initial Object
const initialState = {
  data: null,
  loading: true,
  error: false,
};

export default function tourReducer(
  state: TourState = initialState,
  action: { type: string; payload: TourData }
) {
  switch (action.type) {
    // Onboarding Tour Details
    case tourTypes.ONBOARDING_DETAILS_START: {
      return { ...state, loading: true, error: false };
    }
    case tourTypes.ONBOARDING_DETAILS_END: {
      return { ...state, data: action.payload, loading: false, error: false };
    }
    // Onboarding Tour Complete By Id
    case tourTypes.ONBOARDING_TOUR_COMPLETE_BY_ID_START: {
      return { ...state, data: action.payload, loading: true, error: false };
    }
    case tourTypes.ONBOARDING_TOUR_COMPLETE_BY_ID_END: {
      return { ...state, data: action.payload, loading: false, error: false };
    }
    // Onboarding Skip Tour
    case tourTypes.ONBOARDING_SKIP_TOUR_START: {
      return { ...state, loading: true, error: false };
    }
    case tourTypes.ONBOARDING_SKIP_TOUR_END: {
      return { ...state, data: action.payload, loading: false, error: false };
    }
    // Onboarding Restart Tour
    case tourTypes.ONBOARDING_RESTART_TOUR_START: {
      return { ...state, loading: true, error: false };
    }
    case tourTypes.ONBOARDING_RESTART_TOUR_END: {
      return { ...state, data: action.payload, loading: false, error: false };
    }
    // Onboarding Tour Completed
    case tourTypes.ONBOARDING_COMPLETED_TOUR_START: {
      return { ...state, loading: true, error: false };
    }
    case tourTypes.ONBOARDING_COMPLETED_TOUR_END: {
      return { ...state, data: action.payload, loading: false, error: false };
    }
    // Loading
    case tourTypes.SET_LOADING: {
      return { ...state, loading: false, error: false };
    }
    default: {
      return state;
    }
  }
}
