import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Tabs } from "@shopify/polaris";
import findIndex from "lodash/findIndex";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";

const tabsList = (t: TFunction) => [
  {
    id: "Dashboard",
    //@ts-ignore ts(2589)
    content: t("common:topBar:dashboard"),
    accessibilityLabel: "Dashboard",
    panelID: "Dashboard",
    route: "/app",
  },
  {
    id: "Create-NFT",
    content: t("common:topBar:createNft"),
    accessibilityLabel: "Create NFT",
    panelID: "Create-NFT",
    route: "/app/add-draft-nft",
  },
  {
    id: "Import-NFT",
    content: t("common:topBar:importNft"),
    accessibilityLabel: "Import NFT (Beta)",
    panelID: "Import-NFT",
    route: "/app/import-nft",
  },
  {
    id: "Draft-NFT",
    content: t("common:topBar:draftNft"),
    accessibilityLabel: "Draft NFT",
    panelID: "Draft NFT",
    route: "/app/draft-nft",
  },
  {
    id: "Published-NFT",
    content: t("common:topBar:publishedNft"),
    accessibilityLabel: "Published NFT",
    panelID: "Published NFT",
    route: "/app/published-nft",
  },
  {
    id: "Fulfillments",
    content: t("common:topBar:fulfillmentOrders"),
    accessibilityLabel: "Fulfillment Orders",
    panelID: "Fulfillment-Orders",
    route: "/app/fulfillment-orders",
  },

  {
    id: "Introduction-Tour",
    content: t("common:topBar:introductionTour"),
    accessibilityLabel: "Introduction Tour",
    panelID: "Introduction Tour",
    route: "/app/introduction-tour",
  },

  {
    id: "FAQ",
    content: t("common:topBar:faq"),
    accessibilityLabel: "FAQ",
    panelID: "FAQ",
    route: "/app/faq",
  },
];

type MergedProps = WithTranslation & RouteComponentProps;
type State = { selected: number };
class TopBarMarkup extends Component<MergedProps, State> {
  constructor(props: MergedProps) {
    super(props);
    this.state = {
      selected: 0,
    };
  }
  componentDidMount() {
    const selected = findIndex(tabsList(this.props.t), {
      route: this.props.history.location.pathname,
    });

    if (selected >= 0) {
      this.setState({ selected: selected });
    }
  }

  handleClickNewNft = () => {
    this.props.history.push("/app/add-draft-nft");
  };

  componentWillReceiveProps() {
    const selected = findIndex(tabsList(this.props.t), {
      route: this.props.history.location.pathname,
    });

    if (selected >= 0) {
      this.setState({ selected: selected });
    }
  }

  handleTabChange = (selectedTabIndex: number) => {
    this.setState({ selected: selectedTabIndex });
    this.props.history.push(
      `${tabsList(this.props.t)[selectedTabIndex].route}`
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className="topbar-shopify"
          style={{ background: "#f6f6f7", boxShadow: "1px 1px 5px 3px #dddd" }}
        >
          <Tabs
            tabs={tabsList(t)}
            selected={this.state.selected}
            onSelect={this.handleTabChange}
          />
        </div>
      </>
    );
  }
}

//@ts-ignore ts(2589)
export default withRouter(withTranslation()(TopBarMarkup));
