import { toastTypes } from "./toastTypes";

export const openToast = (payload: any) => ({
  type: toastTypes.OPEN_REQUEST,
  payload: payload,
});

export const closeToast = () => ({
  type: toastTypes.CLOSE_REQUEST,
});
