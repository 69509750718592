import { Component } from "react";
import { Dispatch, Action } from "redux";
import { Toast } from "@shopify/polaris";
import { connect } from "react-redux";
import { RootState } from "../../redux/Store";
import { closeToast } from "../../redux/toast/toastActions";

//type Props = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MergedProps = StoreProps & DispatchProps; // & Props;

class ToastMarkup extends Component<MergedProps> {
  render() {
    return this.props.isActive ? (
      <Toast
        content={this.props.message}
        error={this.props.isError}
        onDismiss={this.props.closeToast}
      />
    ) : null;
  }
}

const mapStateToProps = (state: RootState) => ({
  isActive: state.toast.isActive,
  isError: state.toast.isError,
  message: state.toast.message,
});
const mapDispatchToProps = (dispatch: Dispatch<Action<unknown>>) => ({
  closeToast: () => dispatch(closeToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastMarkup);
