import { tourTypes } from "./tourTypes";

// Onboarding Start
export const onboardingStart = (data: any) => ({
  type: tourTypes.ONBOARDING_DETAILS_START,
  payload: data,
});
export const onboardingEnd = (data: any) => ({
  type: tourTypes.ONBOARDING_DETAILS_END,
  payload: data,
});

type BoardingCompleteData = {
  completed: Array<number>;
  count: number | null;
  id: number;
};

// Onboarding Tour Complete By Id
export const onBoardingCompleteByIdStart = (data: BoardingCompleteData) => ({
  type: tourTypes.ONBOARDING_TOUR_COMPLETE_BY_ID_START,
  payload: data,
});
export const onBoardingCompleteByIdEnd = (data: any) => ({
  type: tourTypes.ONBOARDING_TOUR_COMPLETE_BY_ID_END,
  payload: data,
});

// Onboarding Restart Tour
export const onBoardingRestartTourStart = (data: any) => ({
  type: tourTypes.ONBOARDING_RESTART_TOUR_START,
  payload: data,
});
export const onBoardingRestartTourEnd = (data: any) => ({
  type: tourTypes.ONBOARDING_RESTART_TOUR_END,
  payload: data,
});

// Onboarding Tour Completed
export const onBoardingCompletedToursStart = (data: any) => ({
  type: tourTypes.ONBOARDING_COMPLETED_TOUR_START,
  payload: data,
});
export const onBoardingCompletedToursEnd = (data: any) => ({
  type: tourTypes.ONBOARDING_COMPLETED_TOUR_END,
  payload: data,
});
