import { lazy, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Spinner } from "@shopify/polaris";

const Dashboard = lazy(() => import("../../pages/Dashboard"));
const DraftNft = lazy(() => import("../../pages/DraftNft"));
const ImportNft = lazy(() => import("../../pages/ImportNft/index"));
const AddDraftNFT = lazy(() => import("../../pages/AddDraftNFT"));
const EditNft = lazy(() => import("../../pages/EditNft"));
const PublishNFT = lazy(() => import("../../pages/PublishNFT"));
const OrderHistory = lazy(() => import("../../pages/OrderHistory"));
const IntroductionTour = lazy(
  () => import("../../pages/tour/IntroductionTour")
);
const Faq = lazy(() => import("../../pages/Faq"));

function Routes() {
  return (
    <Switch>
      <Suspense
        fallback={
          <div>
            <Spinner size="large"></Spinner>
          </div>
        }
      >
        <Route path="/app" component={Dashboard} exact></Route>
        <Route path="/app/draft-nft" component={DraftNft} exact></Route>
        <Route path="/app/import-nft" component={ImportNft} exact></Route>
        <Route path="/app/add-draft-nft" component={AddDraftNFT} exact></Route>
        <Route path="/app/edit-draft-nft/:id" component={EditNft} exact></Route>
        <Route path="/app/published-nft" component={PublishNFT} exact></Route>
        <Route
          path="/app/fulfillment-orders"
          component={OrderHistory}
          exact
        ></Route>
        <Route
          path="/app/introduction-tour"
          component={IntroductionTour}
        ></Route>
        <Route path="/app/faq" component={Faq} exact></Route>
      </Suspense>
    </Switch>
  );
}

export default withRouter(Routes);
