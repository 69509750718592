import React from "react";
import ReactDOM from "react-dom";
import "@shopify/polaris/build/esm/styles.css";
import "./index.css";
import App from "./App";
import "./i18n";

declare global {
  interface Window {
    simply: unknown;
    cn: (o: undefined | null | string) => boolean;
    cb: (o: string | null) => boolean;
    [key: string]: unknown;
  }
}

window.simply = window.simply || {};
window.cn = function (o: undefined | null | string) {
  return "undefined" === typeof o || null === o || "" === o.toString().trim();
};
window.cb = function (o: string | null) {
  if (o === "true") {
    return true;
  } else {
    return false;
  }
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
